<template>
  <div style="height: 1px">
    <slot />
  </div>
</template>

<script>
export default {
  beforeDestroy() {
    this.observer.unobserve(this.$el);
  },
  mounted() {
    const el = this.$el;
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // In that case we can refine
          this.$emit("loadMore");
        }
      });
    });

    this.observer.observe(el);
  },
};
</script>

<style lang="scss" scoped>
</style>