<template>
  <div>
    <template v-if="syncs.length">
      <div class="headings__container">
        <span class="production-heading">Featured In</span>
        <span class="date-heading"> Date</span>
        <span class="studio-heading">Network / Studio</span>
      </div>

      <section class="details__section">
        <div
          class="details__row"
          v-for="item in syncs"
          v-bind:key="item.collection_id"
        >
          <div class="sync-image__col">
            <sync-image-cell v-bind:type="item.type"></sync-image-cell>
          </div>

          <div class="production-name__col">
            <component
              :is="item.production_id ? 'router-link' : 'span'"
              class="production-name"
              v-bind:to="'/production/' + item.production_id"
            >
              {{ item.production }}
            </component>
          </div>

          <div class="date-name__col">
            <span class="date-name">
              {{ item.date }}
            </span>
          </div>

          <div class="producer-name__col">
            <component
              :is="item.producer_id ? 'router-link' : 'span'"
              :to="`/producer/${item.producer_id}`"
              class="producer-name"
            >
              {{ item.producer || "-" }}
            </component>
          </div>
        </div>
      </section>
    </template>
    <template v-else>
      <section class="details__section">
        <p>No sync data found ...</p>
      </section>
    </template>
  </div>
</template>

<script>
import SyncImageCell from "@/components/common/SyncImageCell";

export default {
  props: {
    syncs: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    SyncImageCell,
  },
};
</script>

<style lang="scss" scoped>
.headings__container {
  display: none;
  @include tablet {
    display: flex;
    margin-bottom: 1rem;

    & > span {
      color: $color-primary;
    }
  }
}

.details__section {
  height: 255px;
  overflow: auto;
  margin-bottom: 2rem;
  @include tablet {
    height: 180px;
  }
}

.details__row {
  margin-bottom: 1.25rem;

  @include mobile {
    display: grid;
    grid-template-columns: 1fr 7fr;
    grid-column-gap: 0.5rem;
    grid-template-areas:
      "sync-image production"
      "sync-image producer";
  }

  @include tablet {
    display: flex;
  }
}

.sync-image__col {
  grid-area: sync-image;
  @include tablet {
    flex-basis: 1.7rem;
    margin-right: 1.25rem;
  }
}

.date {
  &-heading {
    flex-basis: 20%;
  }

  &-name {
    color: hsla(204, 4, 23, 1);
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;

    @include tablet {
      color: $color-text;
      font-size: 22px;
      line-height: 1.2;
      font-weight: 400;
    }
  }

  &-name__col {
    display: none;
    @include tablet {
      display: block;
      flex-basis: 20%;
    }
  }
}

.production {
  &-heading {
    flex-basis: 40%;
  }

  &-name {
    @include tablet {
      color: $color-text;
      font-size: 24px;
      line-height: 1.2;
      font-weight: bold;
    }
  }

  &-name__col {
    grid-area: production;
    @include tablet {
      flex-basis: calc(40% - 2.95rem);
    }
  }
}

.producer {
  &-heading {
    flex-basis: 40%;
  }

  &-name {
    @include mobile {
      color: $color-primary;
      font-size: 16px;
      font-weight: 400;
    }
  }

  &-name__col {
    grid-area: producer;
    @include tablet {
      flex-basis: 40%;
    }
  }
}
</style>
