<template>
  <div>
    <div class="headings__container">
      <span class="album-heading">Album:</span>
      <span class="artist-heading"> Performer:</span>
      <span class="label-heading">Distributor:</span>
    </div>

    <section class="recording__section">
      <div
        class="recording__row"
        v-for="(item, itemIndex) in releases"
        v-bind:key="item.collection_id"
      >
        <div class="audio__col">
          <play-audio
            ref="playAudio"
            @play="onStartPlayAudio($event, itemIndex)"
            v-bind:audioSrc="item.preview_url"
          ></play-audio>
        </div>
        <div class="album-name__col">
          <router-link
            class="album-name"
            v-bind:to="'/release/' + item.apple_collection_id"
          >
            {{ item.album_name }}
          </router-link>
        </div>

        <div class="artist-name__col">
          <router-link
            class="artist-name"
            v-bind:to="`/artist/${item.apple_artist_id}?artist_name=${item.artist_name}`"
          >
            {{ item.artist_name }}
          </router-link>
        </div>

        <div class="label__col">
          {{ item.provider }}
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PlayAudio from "@/components/common/PlayAudio";
export default {
  props: {
    releases: {
      type: Array,
      default: () => [],
    },
    /*artist_name: {
      type: String,
    },
    artist_id: {
      type: [String, Number],
    },*/
  },
  components: {
    PlayAudio,
  },
  methods: {
    onStartPlayAudio(el, index) {
      console.log("on start play", { el, index });
      if (Number.isInteger(this.$options.currentlyPlayingAudioIndex)) {
        this.$refs.playAudio[this.$options.currentlyPlayingAudioIndex].pause();
      }
      this.$options.currentlyPlayingAudioIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.headings__container {
  display: none;
  @include tablet {
    display: flex;
    margin-bottom: 1rem;

    & > span {
      color: $color-primary;
    }
  }
}

.recording__section {
  height: 255px;
  overflow: auto;
  margin-bottom: 2rem;
  @include tablet {
    height: 180px;
  }
}

.recording__row {
  margin-bottom: 1.25rem;

  @include mobile {
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-column-gap: 0.5rem;
    grid-template-areas:
      "audio artist"
      "audio album";
  }

  @include tablet {
    display: flex;
  }
}

.audio__col {
  grid-area: audio;
  @include tablet {
    flex-basis: 1.7rem;
    margin-right: 1.25rem;
  }
}

.artist {
  &-heading {
    flex-basis: 20%;
  }

  &-name {
    color: hsla(204, 4, 23, 1);
    font-weight: bold;
    font-size: 24px;
    line-height: 1.2;

    @include tablet {
      color: $color-text;
      font-size: 22px;
      line-height: 1.2;
      font-weight: 500;
    }
  }

  &-name__col {
    grid-area: artist;
    @include tablet {
      flex-basis: 20%;
    }
  }
}

.album {
  &-heading {
    flex-basis: 40%;
  }

  &-name {
    color: $color-primary;
    font-size: 16px;
    font-weight: 500;

    @include tablet {
      color: $color-text;
      font-size: 24px;
      line-height: 1.2;
      font-weight: bold;
    }
  }

  &-name__col {
    grid-area: album;
    @include tablet {
      flex-basis: calc(40% - 2.95rem);
    }
  }
}

.label {
  &-heading {
    flex-basis: 40%;
  }

  &__col {
    display: none;
    @include tablet {
      display: block;
      flex-basis: 40%;
    }
  }
}
</style>